@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');


html {
    font-family: "Montserrat", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}


@layer components {
  .brute-border {
    @apply border border-black
  }

  .button-animation {
    @apply hover:-translate-x-1 hover:-translate-y-1 transition-all duration-100  hover:shadow-[5px_5px]
  }

  .primary-button {
    @apply flex items-center justify-center h-8 px-2 rounded text-sm bg-primary text-white hover:bg-primary hover:bg-opacity-80 hover:border border-primary  border
  }
  
  .secondary-button {
    @apply flex items-center justify-center h-8 px-2 rounded text-sm bg-neutral-50 hover:bg-black hover:bg-opacity-5 border
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }


  }
}
@layer base {
  img {
    @apply inline-block;
  }
}
